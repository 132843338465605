<template>
<Transition name="slide-fade" appear>
		<router-view></router-view>
</Transition>
</template>

<style scoped>

</style>

<script>
// import Calendar from '@/components/Calendar.vue'

	export default {

		data() {
			return {
				
			}
		},

		methods: {
			
		},
		components: {
			// Calendar
		},
		computed: {
			
		},

		created(){
		}
				
		}

</script>